'use client';

import styled from 'styled-components';
import Image from '@app/core/components/image';
import { TileLabelColor } from '@app/core/components/tile/index';

export const StyledContainer = styled.div`
  background: ${({ theme: { COLOR } }) => COLOR.NEUTRALS.DARK_GREY};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme: { COLOR } }) => COLOR.BLUE.BLUE};
  overflow: visible;
  position: relative;
  z-index: 0;
  margin-bottom: 2.4rem;
  width: 100%;
`;

export const StyledImage = styled(Image)`
  height: 12rem;
  object-fit: cover;
  position: relative;

  &:after {
    content: '';
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const StyledTileContent = styled.div`
  padding: 1.6rem;
`;

export type TileLabelProps = {
  $color: TileLabelColor;
};
export const StyledTileLabel = styled.div<TileLabelProps>`
  font-family: ${({ theme: { FONT } }) => FONT.SECONDARY};

  background: ${({ theme: { COLOR } }) => COLOR.NEUTRALS.DARK_GREY};
  color: ${({ theme: { COLOR } }) => COLOR.NEUTRALS.WHITE};
  padding: 0.8rem;
  border-style: solid;
  border-width: 1px;
  border-image: ${({ $color, theme: { COLOR } }) => {
    const colorMap = {
      [TileLabelColor.WHITE]: `linear-gradient(0deg, ${COLOR.NEUTRALS.WHITE} 0%, ${COLOR.NEUTRALS.WHITE} 100%) 20`,
      [TileLabelColor.BLUE]: `${COLOR.GRADIENTS.BLUE} 20`,
      [TileLabelColor.GREY]: `linear-gradient(0deg, ${COLOR.NEUTRALS.DARK_GREY} 0%, ${COLOR.NEUTRALS.DARK_GREY} 100%) 20`,
    };
    return colorMap[$color];
  }};
  text-shadow: ${({ $color }) => {
    const colorMap = {
      [TileLabelColor.WHITE]: `#309dd8`,
      [TileLabelColor.BLUE]: `#309dd8`,
      [TileLabelColor.GREY]: `transparent`,
    };
    return `0 0 0.8rem ${colorMap[$color]}`;
  }};
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 400;
`;
