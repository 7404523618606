'use client';

import styled, { css } from 'styled-components';
import Image from 'next/image';
import { RankTitle } from '@app/core/components/rank-image/index';

export const StyledContainer = styled.div(() => css``);

export const StyledImage = styled(Image)<{ $rankTitle: RankTitle }>`
  filter: drop-shadow(
    0 0 9px
      ${({ $rankTitle }) => {
        const dropShadowColorMap = {
          [RankTitle.champ]: `rgba(250, 209, 143, 0.4)`,
          [RankTitle.star]: `rgba(240, 240, 240, 0.4)`,
          [RankTitle.pro]: `rgba(179, 222, 255, 0.4)`,
          [RankTitle.challenger]: `rgba(0, 0, 0, 0.4)`,
          [RankTitle.rookie]: `transparent`,
          [RankTitle.locked]: `transparent`,
        };

        return dropShadowColorMap[$rankTitle];
      }}
  );
  height: 100%;
  width: auto;
`;
