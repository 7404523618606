'use client';

import { useEffect, useId } from 'react';

import {
  StyledCloseButton,
  StyledContainer,
  StyledLanguageSelector,
  StyledLanguageSelectorLabel,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledMenu,
  StyledNav,
  StyledOverlay,
} from './style';
import Button from '@app/core/components/button';
import IconNames from '@app/core/components/icon/icon-names';
import { ButtonType, IconPosition } from '@app/core/components/button/const';
import { useToggle } from 'usehooks-ts';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { FormattedMessage, useIntl } from 'react-intl';
import { Column, Container, Row } from '@app/core/components/layout';
import { Heading } from '@app/core/components/typography';
import useKeyTrap from '@app/core/hooks/use-key-trap';
import { UserSessionInformation } from '@app/core/session/types';
import { HeadingTypes } from '@app/core/theme/basic/const';

import { Menu as MenuType } from '@app/dashboard/types/contentful';

interface Props {
  session?: UserSessionInformation;
  menu?: MenuType;
}

function Menu({ session, menu }: Readonly<Props>) {
  const languageSelectorId = useId();
  const router = useRouter();
  const params = useParams();
  const { formatMessage } = useIntl();
  const pathname = usePathname();
  const [isMenuOpen, toggleMenuOpen, setIsMenuOpen] = useToggle(false);
  const menuRef = useKeyTrap<HTMLDivElement>(isMenuOpen, {
    onEscape: () => {
      setIsMenuOpen(false);
    },
  });

  const animation = {
    initial: isMenuOpen ? 'open' : 'collapsed',
    animate: isMenuOpen ? 'open' : 'collapsed',
    variants: {
      open: {
        height: 'auto',
      },
      collapsed: {
        height: 0,
      },
    },
    transition: {
      ease: [0.45, 0, 0.55, 1],
      duration: 0.4,
    },
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [pathname, setIsMenuOpen]);

  return (
    <>
      <Button
        label={formatMessage({ id: 'menu.open-button.label' })}
        icon={{
          name: IconNames.SETTINGS,
          position: IconPosition.left,
        }}
        buttonType={ButtonType.inline}
        aria-label={formatMessage({ id: 'menu.open-button.aria-label' })}
        onClick={() => {
          toggleMenuOpen();
        }}
      />
      <StyledOverlay
        $isOpen={isMenuOpen}
        onClick={() => {
          toggleMenuOpen();
        }}
      />
      <StyledMenu
        $isOpen={isMenuOpen}
        ref={menuRef}
        {...animation}
        inert={!isMenuOpen}
      >
        <StyledContainer>
          <Container>
            <Row>
              <Column columns={{ XXS: 12 }}>
                <StyledNav>
                  <StyledCloseButton
                    buttonType={ButtonType.inline}
                    icon={{ name: IconNames.CLOSE }}
                    aria-label={formatMessage({
                      id: 'menu.close-button.aria-label',
                    })}
                    onClick={() => {
                      toggleMenuOpen();
                    }}
                  ></StyledCloseButton>
                  <Heading type={HeadingTypes.HEADING_1}>
                    <FormattedMessage id={'menu.title'} />
                  </Heading>
                  <StyledList>
                    <StyledListItem>
                      {menu?.fields.links.map(({ fields: { url, label } }) => (
                        <StyledLink
                          key={url}
                          link={{
                            href: url,
                            isExternal: true,
                            openInNewWindow: true,
                          }}
                        >
                          {label}
                        </StyledLink>
                      ))}
                    </StyledListItem>
                    <StyledListItem>
                      {session ? (
                        <StyledLink
                          link={{
                            href: '/auth/logout',
                            isUsedForAuth: true,
                          }}
                        >
                          <FormattedMessage
                            id={'menu.logout'}
                            values={{ name: session.name }}
                          />
                        </StyledLink>
                      ) : (
                        <StyledLink
                          link={{
                            href: '/auth/login',
                            isUsedForAuth: true,
                          }}
                        >
                          <FormattedMessage id={'menu.login'} />
                        </StyledLink>
                      )}
                    </StyledListItem>
                  </StyledList>
                  <StyledLanguageSelectorLabel htmlFor={languageSelectorId}>
                    <FormattedMessage id={'menu.language'} />
                  </StyledLanguageSelectorLabel>
                  <StyledLanguageSelector
                    id={languageSelectorId}
                    value={params.locale}
                    onChange={(e) => {
                      e.preventDefault();
                      router.push(`/${e.target.value}`);
                    }}
                  >
                    {menu?.fields.languageSelector.map(
                      ({ fields: { locale, label } }) => (
                        <option key={locale} value={locale}>
                          {label}
                        </option>
                      )
                    )}
                  </StyledLanguageSelector>
                </StyledNav>
              </Column>
            </Row>
          </Container>
        </StyledContainer>
      </StyledMenu>
    </>
  );
}

export default Menu;
