import {
  createContentfulContentModel,
  ImageSchema,
} from '@app/core/types/contentful';
import { z } from 'zod';

export const QuizAnswerSchema = createContentfulContentModel(
  z.literal('quizAnswer'),
  z.object({
    name: z.string(),
    label: z.string(),
  })
);

export type QuizAnswer = z.infer<typeof QuizAnswerSchema>;

export const QuestionBaseSchema = z.object({
  question: z.string(),
  answers: QuizAnswerSchema.array(),
  correctAnswer: QuizAnswerSchema,
  maxPoints: z.number(),
  minPoints: z.number(),
  maxTime: z.number(),
  ramp: z.number(),
  rampDelay: z.number(),
  rampInterval: z.number(),
});

export const MultipleChoiceQuestionSchema = createContentfulContentModel(
  z.literal('multipleChoiceQuestion'),
  QuestionBaseSchema
);

export type MultipleChoiceQuestion = z.infer<
  typeof MultipleChoiceQuestionSchema
>;

export const TrueFalseQuestionSchema = createContentfulContentModel(
  z.literal('truefalseQuestion'),
  QuestionBaseSchema.extend({
    thumbnail: ImageSchema,
  })
);

export type TrueFalseQuestion = z.infer<typeof TrueFalseQuestionSchema>;

export const QuizIntermissionSchema = createContentfulContentModel(
  z.literal('quizIntermission'),
  z.object({
    title: z.string(),
    content: z.unknown(),
    image: ImageSchema,
    buttonLabel: z.optional(z.string()),
    buttonColor: z.union([z.literal('Transparent'), z.literal('Blue')]),
    graphic: z.union([
      z.literal('None'),
      z.literal('Gears'),
      z.literal('Wheel'),
    ]),
  })
);

export type QuizIntermission = z.infer<typeof QuizIntermissionSchema>;

export function isMultipleChoiceQuestion(
  input: ContentfulQuizStep
): input is TrueFalseQuestion {
  return MultipleChoiceQuestionSchema.safeParse(input).success;
}

export function isTrueFalseQuestion(
  input: ContentfulQuizStep
): input is TrueFalseQuestion {
  return TrueFalseQuestionSchema.safeParse(input).success;
}

export function isQuizIntermission(
  input: ContentfulQuizStep
): input is QuizIntermission {
  return QuizIntermissionSchema.safeParse(input).success;
}

export type ContentfulQuizStep =
  | QuizIntermission
  | TrueFalseQuestion
  | MultipleChoiceQuestion;

export type QuizQuestion = TrueFalseQuestion | MultipleChoiceQuestion;

export function isQuizQuestion(
  input: ContentfulQuizStep
): input is QuizQuestion {
  return (
    input && (isTrueFalseQuestion(input) || isMultipleChoiceQuestion(input))
  );
}

const QuizLiteral = z.literal('quiz');
const BaseQuizFields = z.object({
  name: z.string(),
  thumbnail: ImageSchema,
  body: QuizIntermissionSchema.or(TrueFalseQuestionSchema)
    .or(MultipleChoiceQuestionSchema)
    .or(z.any())
    .array(),
});

// This doesn't do exactly what we want, but we're probably not going to use
// indefinitely recursive quizzes anyway. So this represents what we want better
export const QuizSchema = createContentfulContentModel(
  QuizLiteral,
  BaseQuizFields.extend({
    requiredQuizzes: createContentfulContentModel(QuizLiteral, BaseQuizFields)
      .array()
      .default([]),
  })
);

export type Quiz = z.infer<typeof QuizSchema>;
