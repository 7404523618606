'use client';

import styled, { css } from 'styled-components';
import { Column, Row } from '@app/core/components/layout';
import RankImage from '@app/core/components/rank-image';
import { Heading, Paragraph } from '@app/core/components/typography';
import Tile from '@app/core/components/tile';

export const StyledContainer = styled.div(() => css``);

export const StyledHeaderColumn = styled(Column)`
  margin-bottom: 2.4rem;
`;

export const StyledDashboardContainer = styled.div`
  position: relative;
`;

export const StyledRow = styled(Row)`
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
`;

export const StyledTile = styled(Tile)<{ $isEnabled: boolean }>`
  border-color: ${({ $isEnabled, theme: { COLOR } }) =>
    $isEnabled ? COLOR.BLUE.BLUE : COLOR.NEUTRALS.DARK_GREY};
`;

export const StyledTileContentContainer = styled.div`
  position: relative;
`;

export const StyledParagraph = styled(Paragraph)<{ $isEnabled: boolean }>`
  color: ${({ $isEnabled, theme: { COLOR } }) =>
    $isEnabled ? COLOR.NEUTRALS.WHITE : COLOR.NEUTRALS.GREY};
`;

export const StyledHeading = styled(Heading)<{ $isEnabled: boolean }>`
  margin: 0.8rem 0 1.6rem;
  color: ${({ $isEnabled, theme: { COLOR } }) =>
    $isEnabled ? COLOR.NEUTRALS.WHITE : COLOR.NEUTRALS.GREY};
`;

export const StyledRankImage = styled(RankImage)`
  position: absolute;
  top: -2.4rem;
  right: 0;
  transform: translateY(-50%);
  height: 10rem;
`;

export const StyledOneLinerContainer = styled.div`
  width: 100%;
  background: ${({ theme: { COLOR } }) => COLOR.NEUTRALS.BLACK};
`;
