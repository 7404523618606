'use client';

import {
  StyledContainer,
  StyledDashboardContainer,
  StyledHeaderColumn,
  StyledHeading,
  StyledOneLinerContainer,
  StyledParagraph,
  StyledRankImage,
  StyledRow,
  StyledTile,
  StyledTileContentContainer,
} from 'src/dashboard/components/dashboard/style';
import { calculateRank } from '@app/core/utils/calculate-rank';
import { validateQuizIsEnabled } from '@app/dashboard/utils/validate-quiz-is-enabled';
import { Module } from '@app/dashboard/types/contentful';
import Header from '@app/core/components/header';
import { Column, Container } from '@app/core/components/layout';
import Button from '@app/core/components/button';
import IconNames from '@app/core/components/icon/icon-names';
import {
  ButtonColor,
  ButtonType,
  IconPosition,
} from '@app/core/components/button/const';
import { FormattedMessage } from '@app/core/components/formatted-message';
import AccordionItem from '@app/core/components/accordion/accordion-item';
import Tile, { TileLabelColor } from '@app/core/components/tile';
import { RankTitle } from '@app/core/components/rank-image';
import Menu from '@app/core/components/menu';
import { UserSessionInformation } from '@app/core/session/types';
import {
  AccordionVariant,
  InitialAccordionItemStates,
} from '@app/core/components/accordion/accordion-item/cont';
import { HeadingTypes } from '@app/core/theme/basic/const';
import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import { isQuizQuestion } from '@app/quiz/types/contentful';
import OneLiner from 'src/core/components/oneliner';

interface Props {
  locale: string;
  modules: Module[];
  completedQuizzes: { quizId: string; score: number }[];
  session?: UserSessionInformation;
  menu?: ComponentProps<typeof Menu>['menu'];
}

function getTileLabelColor(isQuizEnabled: boolean, isCompleted: boolean) {
  if (!isQuizEnabled) {
    return TileLabelColor.GREY;
  }

  return isCompleted ? TileLabelColor.WHITE : TileLabelColor.BLUE;
}

function getTileLabelMessageId(isQuizEnabled: boolean, isCompleted: boolean) {
  if (!isQuizEnabled) {
    return 'dashboard.tile.label.locked';
  }

  return isCompleted
    ? 'dashboard.tile.label.completed'
    : 'dashboard.tile.label.uncompleted';
}

function Dashboard({
  locale,
  modules,
  completedQuizzes,
  session,
  menu,
}: Readonly<Props>) {
  const { formatMessage } = useIntl();
  return (
    <StyledContainer>
      <Header hasFullHeightBackground={false}>
        <Menu session={session} menu={menu} />
        <FormattedMessage
          id="index.title"
          defaultMessage="This is the title of the page"
        />

        <StyledHeaderColumn columns={{ XXS: 12, SM: 4, MD: 3 }}>
          {session && (
            <Button
              buttonType={ButtonType.secondary}
              isForward={true}
              buttonColor={ButtonColor.transparent}
              icon={{
                name: IconNames.STATS_BARS,
                position: IconPosition.left,
              }}
              link={{
                href: `${locale}/leaderboard`,
              }}
            >
              <FormattedMessage id={'dashboard.leader-board-button.label'} />
            </Button>
          )}
        </StyledHeaderColumn>
      </Header>

      <StyledDashboardContainer>
        {modules.map((module, moduleIndex) => {
          const isModuleEnabled = module.fields.quizzes.some((quiz) =>
            validateQuizIsEnabled(
              quiz,
              completedQuizzes.map((q) => q.quizId)
            )
          );

          return (
            <AccordionItem
              key={module.sys.id}
              title={module.fields.name}
              badge={
                !isModuleEnabled
                  ? formatMessage(
                      { id: 'dashboard.module.locked.badge' },
                      { module: modules[moduleIndex - 1]?.fields.name }
                    )
                  : undefined
              }
              titleLevel={'h2'}
              isActive={true}
              variant={
                isModuleEnabled ? AccordionVariant.LIGHT : AccordionVariant.DARK
              }
              initialState={
                moduleIndex <= 1
                  ? InitialAccordionItemStates.OPEN
                  : InitialAccordionItemStates.CLOSED
              }
            >
              <Container>
                <StyledRow>
                  {module.fields.quizzes.map((quiz, quizIndex) => {
                    const current = completedQuizzes.find(
                      (q) => q.quizId === quiz.sys.id
                    );

                    const level = quizIndex + 1;
                    const isCompleted = Boolean(current);
                    const isQuizEnabled = validateQuizIsEnabled(
                      quiz,
                      completedQuizzes.map((q) => q.quizId)
                    );

                    const score = current?.score;

                    const rank = isQuizEnabled
                      ? calculateRank(
                          score ?? 0,
                          quiz.fields.body
                            .filter(isQuizQuestion)
                            .reduce((acc, cur) => acc + cur.fields.maxPoints, 0)
                        )
                      : RankTitle.locked;

                    return (
                      <Column
                        columns={{ XXS: 12, SM: 6, LG: 4 }}
                        key={quiz.sys.id}
                      >
                        <StyledTile
                          $isEnabled={isQuizEnabled}
                          key={quiz.sys.id}
                          thumbnail={{
                            src: quiz.fields.thumbnail.fields.file.url,
                            width:
                              quiz.fields.thumbnail.fields.file.details.image
                                .width,
                            height:
                              quiz.fields.thumbnail.fields.file.details.image
                                .height,
                            alt: quiz.fields.thumbnail.fields.title,
                          }}
                        >
                          <Tile.TileLabel
                            color={getTileLabelColor(
                              isQuizEnabled,
                              isCompleted
                            )}
                          >
                            <FormattedMessage
                              id={getTileLabelMessageId(
                                isQuizEnabled,
                                isCompleted
                              )}
                              values={{
                                level,
                              }}
                            />
                          </Tile.TileLabel>
                          <StyledTileContentContainer>
                            <StyledRankImage
                              rankTitle={
                                !isQuizEnabled ? RankTitle.locked : rank
                              }
                            />
                            <StyledParagraph $isEnabled={isQuizEnabled}>
                              Score: <strong>{score ?? '-'}</strong>
                              &nbsp; Rank:{' '}
                              <strong>
                                {rank === RankTitle.locked || !score ? (
                                  '-'
                                ) : (
                                  <FormattedMessage
                                    id={`quiz.end-screen.rank.value.${rank}`}
                                  />
                                )}
                              </strong>
                            </StyledParagraph>
                            <StyledHeading
                              $isEnabled={isQuizEnabled}
                              type={HeadingTypes.HEADING_5}
                              forwardedAs={'h3'}
                            >
                              {quiz.fields.name}
                            </StyledHeading>
                            {isQuizEnabled && (
                              <Button
                                buttonType={ButtonType.primary}
                                link={{
                                  href: `/${locale}/quiz/${quiz.sys.id}`,
                                }}
                                isForward={true}
                              >
                                <FormattedMessage
                                  id={
                                    isCompleted
                                      ? 'dashboard.tile.button.label.completed'
                                      : 'dashboard.tile.button.label.uncompleted'
                                  }
                                  values={{
                                    level,
                                  }}
                                />
                              </Button>
                            )}
                          </StyledTileContentContainer>
                        </StyledTile>
                      </Column>
                    );
                  })}
                </StyledRow>
              </Container>
            </AccordionItem>
          );
        })}
      </StyledDashboardContainer>
      <StyledOneLinerContainer>
        <OneLiner />
      </StyledOneLinerContainer>
    </StyledContainer>
  );
}

export default Dashboard;
